import styled from '@emotion/styled';
import { DEVICE_SCREENS_MAX_STRING } from '../../lib/consts';

export const h1 = {
  fontWeight: 700,
  fontSize: '32px',
  [DEVICE_SCREENS_MAX_STRING.md]: {
    fontSize: '22px',
  },
};
export const h2 = {
  fontWeight: 700,
  fontSize: '2rem',
};
export const h3 = {
  fontWeight: 700,
  fontSize: '1.25rem',
};
export const h4 = {
  fontWeight: 500,
  fontSize: '1rem',
};
export const p = {
  fontWeight: 500,
  fontSize: '1rem',
  lineHeight: 1.3,
};
export const h5 = {
  fontWeight: 500,
  fontSize: '0.875rem',
};
export const h6 = {
  fontWeight: 500,
  fontSize: '0.75rem',
};
export const H1 = styled.h1(h1);
export const H2 = styled.h2(h2);
export const H3 = styled.h3(h3);
export const H4 = styled.h4(({ bold = false }) => ({
  ...h4,
  ...(bold ? { fontWeight: 700 } : {}),
}));
export const P = styled.p(h4);
export const H5 = styled.h5(h5);
export const H6 = styled.h6(h6);

export const StyledText = styled.div`
  h2 {
    font-family: 'Golos';
    font-size: 21px;
    color: #000;
    margin: 20px 0;
    font-weight: 700;
  }
  p:not(:last-child) {
    margin-bottom: 40px;
  }
  ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }
  ul > li {
    list-style-type: inherit !important;
    display: list-item;
    ::marker {
      unicode-bidi: isolate;
      font-variant-numeric: tabular-nums;
      text-transform: none;
      text-indent: 0px !important;
      text-align: start !important;
      text-align-last: start !important;
    }
  }
  ul ul {
    display: block;
    list-style-type: circle;
  }
  ul ul ul {
    list-style-type: square;
  }
  ol {
    display: block;
    list-style-type: decimal;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }
`;
